import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useContext, useEffect, useState } from "react";
import { TbArrowUpRight } from "react-icons/tb";
import { toast } from "react-toastify";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";

import { cspmAPI } from "../../../../Api/CSPM/api";
import { ThemeContext } from "../../../../context/ThemeContext";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { isAdminOrSuperAdmin } from "../../../../utils/accessCheck";

export default function CloudIntegration({ type, setCurrentConfig }) {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);

  const [loadingTable, setLoadingTable] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(false);

  const getData = () => {
    cspmAPI
      .get("/cloud-integration", { type })
      .then((res) => {
        setData(res.data);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingTable(false);
      });
  };

  const removeIntegration = (id) => {
    setLoadingTable(true);
    cspmAPI
      .delete(`/cloud-integration/${id}`)
      .then((res) => {
        getData();
        toast.success("Integration removed successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoadingTable(false);
      });
  };

  const reCheck = (id) => {
    cspmAPI
      .post(`/cloud-integration/${id}/recheck`)
      .then((res) => {
        if (res.status === "success") {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      field: "account_id",
      header: "Account ID",
    },
    {
      field: "provider",
      header: "Provider",
    },
    {
      field: "regions",
      header: "Regions",
    },
    {
      field: "resource_count",
      header: "Resource Count",
    },
    {
      field: "status",
      header: "Status",
    },
    {
      field: "actions",
      header: "Actions",
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={[
            // {
            //   label: "Re-Check",
            //   command: () => {
            //     reCheck(id);
            //   },
            // },
            {
              label: "Edit",
              command: () => {
                setVisibleRight(true);
                setEditData(data);
              },
            },
            {
              label: "Remove",
              command: () => {
                removeIntegration(data.id);
              },
            },
          ]}
        ></SplitButton>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="flex mb-2" style={{ justifyContent: "end" }}>
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base"
            onClick={() => setCurrentConfig(null)}
          >
            Back
            <TbArrowUpRight />
          </button>
        </div>
      </div>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background text-color"
        }`}
      >
        <div className="flex gap-4 justify-between">
          <p className="text-[16px] font-bold pb-2">Cloud Integration</p>
          <button
            className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
              isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
            }`}
            onClick={() => setVisibleRight(true)}
          >
            Integrate
          </button>
        </div>
        {loadingTable ? (
          <SkeletonDataTable columns={columns} length={true} />
        ) : (
          <div>
            <DataTable
              removableSort
              value={data}
              paginator
              rows={10}
              paginatorTemplate={paginationTemplate()}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              <Column
                field="account_id"
                header="Account ID"
                body={(data) => data.account_id || "Creating is not completed"}
              />
              <Column
                field="provider"
                header="Provider"
                body={({ provider }) => provider.toUpperCase()}
              />
              <Column
                field="regions"
                header="Regions"
                body={({ regions }) => regions}
              />
              {(isAdminOrSuperAdmin(userDetails?.role) ||
                userDetails.role === "MT Admin") && (
                <Column field="user_info" header="User" />
              )}
              <Column field="resource_count" header="Resource Count" />
              <Column
                field="status"
                header="Status"
                body={({ status }) => {
                  const _status = status.toUpperCase();
                  if (status === "active") {
                    return <span className="text-[#148842]">{_status}</span>;
                  } else if (status === "creating") {
                    return <span className="text-[#074EE8]">{_status}</span>;
                  } else {
                    return <span className="text-[#E8001D]">{_status}</span>;
                  }
                }}
              />
              <Column
                field="actions"
                header="Actions"
                body={(data) => actionBodyTemplate(data)}
              />
            </DataTable>
          </div>
        )}
        <SidebarSlide
          title="Cloud Integration"
          visibleRight={visibleRight}
          description="Integrate your cloud account with Truzta."
          setVisibleRight={setVisibleRight}
          editData={editData}
          getTableFilterData={getData}
        />
      </div>
    </>
  );
}
