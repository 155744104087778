import { useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { FaRegClipboard } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import ListWithReadMore from "../../../modules/sidebar/components/drp/ListWithReadMore";
import { SEVERITY_COLOR, STATUS_COLOR } from "../variables";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";

export default function DetailedView({ currentDetails, setCurrentDetails }) {
  const userDetails = useSelector(getUserDetails);
  const provider = userDetails?.cspm_provider;
  const codeRef = useRef(null);

  const renderCode = (code, resource_info) => {
    // if any of the replacable string is present in the code, replace it with the actual value
    const replacable = {
      "<bucket_name>": resource_info.name,
      "<bucket-name>": resource_info.name,
      "<VOLUME-ID>": resource_info.name,
      "<SECRET-ID>": resource_info.name,
      "<STACK_NAME>": resource_info.name,
      "<db_instance_id>": resource_info.name,
      "<key_id>": resource_info.name,
      "<cluster_name>": resource_info.name,
      "<account_id>": resource_info.uid.split(":")[4],
      "<REGION>": resource_info.region,
      "<REGION_NAME>": resource_info.region,
    };

    Object.keys(replacable).forEach((item) => {
      code = code.replace(item, replacable[item]);
    });

    return (
      <div className="flex justify-between pt-6">
        <div className="w-full">
          <h1 className="text-sm text-color font-bold">CLI Command</h1>
          <pre className="text-xs text-color font-normal leading-5 bg-[#F5F5F5] p-2 rounded-lg">
            <code
              ref={codeRef}
              className="text-xs text-color font-normal leading-5"
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {code.split(" ").map((item, index) => {
                const editable = item.startsWith("<") && item.endsWith(">");
                return (
                  <>
                    <span
                      key={index}
                      contentEditable={editable}
                      className={`${
                        editable ? "cursor-pointer opacity-50" : ""
                      }`}
                    >
                      {item}{" "}
                    </span>
                  </>
                );
              })}
            </code>
            <FaRegClipboard
              className="w-[20px] float-right"
              title="Copy to clipboard"
              onClick={() => {
                navigator.clipboard.writeText(codeRef.current.innerText);
                toast.success("Copied to clipboard");
              }}
            />
          </pre>

          {/* click to remediate */}
          <div className="flex justify-end">
            <button
              className="my-2 p-2 border  rounded-[16px] border-[#AAAAAA] bg-[#181818] text-[#ffff] cursor-pointer"
              onClick={() => {
                // open aws console with the remediation command
                navigator.clipboard.writeText(codeRef.current.innerText);
                window.open(
                  `https://console.aws.amazon.com/cloudshell/home?region=${resource_info.region}`,
                  "_blank"
                );
              }}
            >
              Click to remediate
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={() => setCurrentDetails(null)}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl text-center">
        {currentDetails?.message}
      </p>
      <div className="px-6">
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Resource ID</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.resource_info?.name}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Resource ARN</h1>
            <p className="text-xs text-color font-normal leading-5 break-all">
              {currentDetails?.resource_info?.uid}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Severity</h1>
            <p className="text-xs text-color font-normal leading-5">
              <span className={SEVERITY_COLOR[currentDetails?.severity]}>
                {currentDetails?.severity}
              </span>
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Status</h1>
            <p className="text-xs text-color font-normal leading-5">
              <span className={STATUS_COLOR[currentDetails?.status]}>
                {currentDetails?.status}
              </span>
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Region</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.resource_info?.region}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Resource Group</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.resource_info?.group?.name}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Check ID</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.check_info?.uid}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Check</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.check_info?.title}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Details</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.check_info?.desc}
              {provider == "azure" && currentDetails?.check_info?.src_url && (
                <a
                  href={currentDetails?.check_info?.src_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-xs font-normal leading-5 text-[#074EE8]">
                    (Read more...)
                  </span>
                </a>
              )}
            </p>
          </div>
        </div>
        {currentDetails?.check_info?.types?.length ? (
          <div className="flex justify-between pt-6">
            <div className="w-full">
              <h1 className="text-sm text-color font-bold">Types</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.check_info?.types.join(", ")}
              </p>
            </div>
          </div>
        ) : null}
        <div className="flex justify-between pt-6">
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Risk</h1>
            <p className="text-xs text-color font-normal leading-5">
              {provider == "aws"
                ? currentDetails?.check_info?.supporting_data?.Risk
                : provider == "azure"
                ? currentDetails?.check_info?.supporting_data?.more_info
                : null}
              {provider == "aws" && currentDetails?.check_info?.src_url && (
                <a
                  href={currentDetails?.check_info?.src_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-xs font-normal leading-5 text-[#074EE8]">
                    (Read more...)
                  </span>
                </a>
              )}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Compliance</h1>
            <p className="text-xs text-color font-normal leading-5">
              <ListWithReadMore
                data={
                  provider == "aws"
                    ? currentDetails?.compliance?.requirements
                    : provider == "azure"
                    ? currentDetails?.compliance?.map((item) => {
                        const LABELS = {
                          iso: "ISO 27001",
                          soc2: "SOC 2",
                          gdpr: "GDPR",
                        };
                        return LABELS[item];
                      })
                    : null
                }
              />
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Recommendation</h1>
            <p className="text-xs text-wrap text-color font-normal leading-5">
              {provider === "aws"
                ? currentDetails?.check_info?.remediation?.desc
                : provider === "azure"
                ? currentDetails?.check_info?.remediation
                : null}
            </p>
            {provider === "aws" && (
              <>
                <ListWithReadMore
                  data={currentDetails?.check_info?.remediation?.kb_articles.filter(
                    (item) => !item.includes("aws ")
                  )}
                />
                {currentDetails?.check_info?.remediation?.cli_command ||
                currentDetails?.check_info?.remediation?.kb_articles.filter(
                  (item) => item.includes("aws ")
                ).length
                  ? renderCode(
                      currentDetails?.check_info?.remediation?.cli_command ||
                        currentDetails?.check_info?.remediation?.kb_articles.filter(
                          (item) => item.includes("aws ")
                        )[0],
                      currentDetails?.resource_info
                    )
                  : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
