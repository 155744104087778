import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import SecureScore from "./SecureScore";
import { BsInfoCircle } from "react-icons/bs";
import { BiGlobe } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Domain,
  LiveHosts,
  OpenPorts,
  subDomain,
  ip,
  endPoints,
  aws,
  AWSWhite,
  Azure,
  google,
  DigitalOcean,
  InformationIconDark,
  InformationIconLight,
} from "../../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Assests = (props) => {
  const {
    assetsData,
    secureScoreData,
    secureScoreGraphCategory,
    secureScoreGraphData,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const slug = useParams();

  const handleNavigate = (e, type) => {
    e.preventDefault();
    if (type === "live-hosts") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "open-ports") {
      navigate(`/attack-surface-management/${type}`, {
        state: { port_state: "open" },
      });
    }
    if (type === "sub-domain") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "ip-address") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "end-points") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "aws") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "azure") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "google-cloud") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "digital-ocean") {
      navigate(`/attack-surface-management/${type}`);
    }
    if (type === "dns-record") {
      navigate(`/attack-surface-management/${type}`);
    }
  };

  useEffect(() => {}, [secureScoreData]);

  return (
    <div>
      <div className="pb-6">
        <h1 className="text-base font-bold">Assets</h1>
      </div>
      <div className="flex lg:flex-row flex-col justify-evenly gap-6 pb-10">
        <div
          className={`w-full lg:w-[50%] ${
            isDarkMode ? "header-dark" : "bg-white"
          } rounded-2xl`}
        >
          <SecureScore
            secureScoreGraphCategory={secureScoreGraphCategory}
            secureScoreGraphData={secureScoreGraphData}
            avg={secureScoreData?.average_score}
            progressBar={[
              {
                name: "Web Server",
                value: secureScoreData?.web_server_vulnerability || 0,
              },
              {
                name: "Web App",
                value: secureScoreData?.web_vulnerability || 0,
              },
              {
                name: "Cloud",
                value: secureScoreData?.cloud_security_misconfig || 0,
              },
            ]}
            tooltip="Truzta Secure Score reflects the current state of your organization's security measures. A higher score denotes weaker security and more risks. A lower score denotes stronger security and less risks."
          />
        </div>

        <div
          className={`w-full ${
            isDarkMode ? "header-dark" : "bg-white"
          } px-3 rounded-2xl`}
        >
          {/* <div className="px-3 flex flex-col h-full"> */}
          <div className="px-3 flex items-center justify-evenly my-5 relative">
            <div className="w-[100%] flex justify-center">
              <h1 className="flex items-center justify-center text-base font-bold leading-normal">
                Assets
              </h1>
            </div>
            <div className="flex justify-end items-end cursor-pointer absolute right-0">
              <img
                data-tooltip-id="asset-tooltip"
                className="w-[18px]"
                src={isDarkMode ? InformationIconDark : InformationIconLight}
                alt="info"
              />
            </div>
          </div>
          <div className="responsiveStyle">
            <div className="flex assetStyle lg:flex-row flex-col gap-3 justify-evenly w-full">
              <div
                className={`flex justify-between items-center asset-padding border-[1px] w-full rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    {/* <BiGlobe size={20} color="#ef4444" /> */}
                    <img className="icon-width" src={Domain} />
                  </div>
                  <Link
                    onClick={(e) => handleNavigate(e, "dns-record")}
                    className="text-sm font-bold"
                  >
                    Domain
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{assetsData.domain}</h1>
                </div>
              </div>
              <div
                className={`flex justify-between items-center w-full asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={ip} />
                  </div>
                  <Link
                    onClick={(e) => handleNavigate(e, "ip-address")}
                    className="text-sm font-bold"
                  >
                    IP Address
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{assetsData.ip_address}</h1>
                </div>
              </div>
            </div>

            <div className="py-3 assetStyle flex lg:flex-row flex-col gap-3 justify-evenly w-full">
              <div
                className={`flex justify-between items-center w-full asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={subDomain} />
                  </div>
                  <Link
                    onClick={(e) => handleNavigate(e, "sub-domain")}
                    className="text-sm font-bold"
                  >
                    Subdomain
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{assetsData.subdomain}</h1>
                </div>
              </div>
              <div
                className={`flex justify-between items-center w-full asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={OpenPorts} />
                  </div>
                  <Link
                    onClick={(e) => handleNavigate(e, "open-ports")}
                    className="text-sm font-bold"
                  >
                    Open Ports
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{assetsData.open_ports}</h1>
                </div>
              </div>
            </div>

            <div className="flex assetStyle lg:flex-row flex-col gap-3 justify-evenly w-full">
              <div
                className={`flex justify-between items-center asset-padding border-[1px] w-full rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    {/* <BiGlobe size={20} color="#ef4444" /> */}
                    <img className="icon-width" src={LiveHosts} />
                  </div>
                  <Link
                    onClick={(e) => handleNavigate(e, "live-hosts")}
                    className="text-sm font-bold"
                  >
                    Live Hosts
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{assetsData.live_hosts}</h1>
                </div>
              </div>
              {/* */}
              <div
                className={`flex justify-between items-center w-full asset-padding border-[1px] rounded-lg ${
                  isDarkMode ? "tab-dark-border" : "border-color"
                }`}
              >
                <div className="flex items-center gap-2">
                  <div className="bg-red-200 p-2 rounded-full">
                    <img className="icon-width" src={endPoints} />
                  </div>
                  <Link
                    onClick={(e) => handleNavigate(e, "end-points")}
                    className="text-sm font-bold"
                  >
                    URL Endpoints
                  </Link>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{assetsData.endpoints}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-full lg:w-[50%] ${
            isDarkMode ? "header-dark" : "bg-white"
          } px-3 rounded-2xl`}
        >
          <div className="px-3 flex items-center justify-evenly my-5 relative">
            <div className="w-[100%] flex justify-center">
              <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
                <span className="truncate" title="Cloud Surface Intelligence">
                  Cloud Surface Intelligence
                </span>
              </h1>
            </div>

            <div className="flex justify-end items-end cursor-pointer absolute right-0">
              <img
                data-tooltip-id="cloud-tooltip"
                className="w-[18px]"
                src={isDarkMode ? InformationIconDark : InformationIconLight}
                alt="info"
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div
              className={`flex justify-between items-center w-full p-3 border-[1px] rounded-lg ${
                isDarkMode ? "tab-dark-border" : "border-color"
              }`}
            >
              <div className="flex items-center gap-2">
                <div>
                  <img
                    src={isDarkMode ? AWSWhite : aws}
                    height={32}
                    width={32}
                  />
                </div>
                <Link
                  onClick={(e) => handleNavigate(e, "aws")}
                  className="text-sm font-bold"
                >
                  AWS
                </Link>
              </div>
              <div>
                <h1 className="text-xl font-bold">{assetsData.aws}</h1>
              </div>
            </div>
            <div
              className={`flex justify-between items-center w-full p-3 border-[1px] rounded-lg ${
                isDarkMode ? "tab-dark-border" : "border-color"
              }`}
            >
              <div className="flex items-center gap-2">
                <div>
                  <img src={Azure} height={32} width={32} />
                </div>
                <Link
                  onClick={(e) => handleNavigate(e, "azure")}
                  className="text-sm font-bold"
                >
                  Azure
                </Link>
              </div>
              <div>
                <h1 className="text-xl font-bold">{assetsData.azure}</h1>
              </div>
            </div>
            <div
              className={`flex justify-between items-center w-full p-3 border-[1px] rounded-lg ${
                isDarkMode ? "tab-dark-border" : "border-color"
              }`}
            >
              <div className="flex items-center gap-2">
                <div>
                  <img src={google} height={32} width={32} />
                </div>
                <Link
                  onClick={(e) => handleNavigate(e, "google-cloud")}
                  className="text-sm font-bold"
                >
                  Google
                </Link>
              </div>
              <div>
                <h1 className="text-xl font-bold">{assetsData.google}</h1>
              </div>
            </div>
            <div
              className={`flex justify-between items-center w-full p-3 border-[1px] rounded-lg ${
                isDarkMode ? "tab-dark-border" : "border-color"
              }`}
            >
              <div className="flex items-center gap-2">
                <div>
                  <img src={DigitalOcean} height={32} width={32} />
                </div>
                <Link
                  onClick={(e) => handleNavigate(e, "digital-ocean")}
                  className="text-sm font-bold"
                >
                  Digital Ocean
                </Link>
              </div>
              <div>
                <h1 className="text-xl font-bold">{assetsData.digitalocean}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <ReactTooltip
        id="asset-tooltip"
        place="bottom-end"
        content="Quickly view all external assets and delve into particular domains or IP Address."
        style={{ maxWidth: "300px" }}
      />
      <ReactTooltip
        id="cloud-tooltip"
        place="bottom-end"
        content="This module detects security misconfigurations and vulnerabilities in AWS, Azure, and GCP, helping organizations quickly address potential cloud infrastructure risks."
        style={{ maxWidth: "300px" }}
      />
    </div>
  );
};

export default Assests;
