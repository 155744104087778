import React from "react";

export const LoginFooter = () => {
  return (
    <footer className="p-2 border-y-[1px] border-[#DEDEDE] flex justify-center items-center shadow">
      <p className="text-[#181818] text-sm font-medium">
        © {new Date().getFullYear()}
        <a target="_blank" href="/" className="ml-1">
          Copyright Truzta
        </a>
      </p>
    </footer>
  );
};
