import React, { useEffect, useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { ThemeContext } from "../../context/ThemeContext";
import { DownloadIcon } from "../../assets/svg";
import TableApi from "../../Api/AuthenticationApi/TableApi";
import { useDispatch } from "react-redux";
import { setUserTableData } from "../../redux-toolkit/redux/TableData";
import { BsDot } from "react-icons/bs";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import paginationTemplate from "../../utils/paginationTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { complianceAPI } from "../../Api/CSPM/api";
import HandleResponseError from "../../utils/error";
import { toast } from "react-toastify";
import Auth0 from "../../services/auth0";

const Tables = (props) => {
  const {
    columns,
    userInformation,
    dt,
    globalFilter,
    setVisibleRight,
    action,
    invoice,
    setTableViewData,
    setShowEdit,
    setSubsData,
    email,
    deleteSubscription,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState(null);

  const items = (data) => [
    {
      label: "Edit",
      icon: "pi pi-refresh",
      command: (e) => {
        setVisibleRight(true);
        setTableViewData(false);
        TableApi.getUserTableDataView(data.id).then((data) => {
          dispatch(setUserTableData(data));
        });
      },
    },
    {
      label: "View",
      icon: "pi pi-times",
      command: (e) => {
        setVisibleRight(true);
        setTableViewData(true);
        TableApi.getUserTableDataView(data.id).then((data) => {
          dispatch(setUserTableData(data));
        });
      },
    },
  ];

  const adminItems = (data) => [
    {
      label: "Edit",
      icon: "pi pi-refresh",
      command: (e) => {
        setSubsData({ ...data, email: email });
        setShowEdit(true);
      },
    },
    {
      label: "Resend Invite",
      icon: "pi pi-plus",
      command: () => {
        confirmDialog({
          header: `Resend Invite ${data.email}`,
          message: "Are you Sure..?",
          icon: "pi pi-info-circle",
          acceptClassName: "p-button-danger",
          acceptLabel: "Resend",
          rejectLabel: "Cancel",
          accept: () => {
            complianceAPI
              .post("/employees/resend-invitations/", {
                users: [data.id],
              })
              .then((res) => {
                if (res.error) {
                  HandleResponseError(res.message);
                } else {
                  toast.success("Resend Invite Successfully.");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
        });
      },
    },
    {
      label: "Resent password reset",
      icon: "pi pi-plus",
      command: () => {
        confirmDialog({
          header: `Resend password reset ${data.email}`,
          message: "Are you Sure..?",
          icon: "pi pi-info-circle",
          acceptClassName: "p-button-danger",
          acceptLabel: "Resend",
          rejectLabel: "Cancel",
          accept: () => {
            Auth0.changePassword(
              {
                connection: "Username-Password-Authentication",
                email: data.email,
              },
              (err, resp) => {
                if (err) toast.error(err.description);
                else toast.success(resp);
              }
            );
          },
        });
      },
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: (e) => {
        deleteSubscription(data.id);
      },
    },
  ];

  const splitBtn = (e, d) => {
    // console.log("dtaa",d,e); /* Getting data here*/
  };

  const actionBodyTemplate = (data) => {
    const userDeatils = data.email;
    const subscription = data.subscriptionPlan;
    const invoice = data.invoice;

    if (invoice) {
      return (
        <a href={invoice} target="_blank">
          <button className="flex gap-2 items-center">
            <img src={DownloadIcon} alt="download" />
          </button>
        </a>
      );
    }
    if (userDeatils) {
      return (
        <>
          <SplitButton
            icon={<FiMoreVertical size={24} />}
            className="p-button-text outline-none split-style"
            model={
              window.location.pathname === "/dashboard/user"
                ? adminItems(data)
                : items(data)
            }
            onClick={(e) => splitBtn(e, data)}
          ></SplitButton>
        </>
      );
    }
    if (subscription) {
      return (
        <button className="flex gap-2 items-center">
          <img src={DownloadIcon} alt="download" />
        </button>
      );
    }
    // if (window.location.pathname === "/dashboard/user") {
    //   return (
    //     <>
    //       <SplitButton
    //         icon={<FiMoreVertical size={24} />}
    //         className="p-button-text outline-none split-style"
    //         model={adminItems(data)}
    //         onClick={(e) => splitBtn(e, data)}
    //       ></SplitButton>
    //     </>
    //   );
    // }
  };

  const handleIcon = () => {};

  return (
    <DataTable
      removableSort
      value={userInformation}
      ref={dt}
      selection={selectedUsers}
      onSelectionChange={(e) => setSelectedUsers(e.value)}
      paginator
      rows={10}
      globalFilterFields={[...columns.map((col) => col.field), "status"]}
      globalFilter={globalFilter}
      paginatorTemplate={paginationTemplate()}
      className={`${
        isDarkMode ? "dark-mode text-dark" : " text-color"
      } text-xs`}
    >
      <Column selectionMode="multiple" exportable={false}></Column>
      {columns.map((col, i) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortIcon={handleIcon}
        />
      ))}
      {action && (
        <Column
          header="Status"
          body={(rowData) => {
            const statusClass =
              rowData.status === "Active"
                ? "activeStatusClass"
                : "progressStatusClass";
            const statusDeatail = rowData.status ? "Active" : "Pending";
            return (
              <div className={`${statusClass} flex gap-1 items-center`}>
                <BsDot size={24} />
                {rowData.status}
              </div>
            );
          }}
          sortable
          pt={{
            sortBadge: { className: "bg-primary" },
            headerCell: { style: { width: "15%" } },
          }}
        />
      )}

      {action && (
        <Column header="Actions" body={actionBodyTemplate} exportable={false} />
      )}
      {invoice && (
        <Column
          header="Status"
          body={(rowData) => {
            const statusClass =
              rowData.status === "Active"
                ? "activeStatusClass"
                : "progressStatusClass";
            const statusDeatail = rowData.status ? "Active" : "Pending";
            return (
              <div className={`${statusClass} flex gap-1 items-center`}>
                <BsDot size={24} />
                {rowData.status}
              </div>
            );
          }}
          sortable
        />
      )}
      {invoice && (
        <Column header="Invoice" body={actionBodyTemplate} exportable={false} />
      )}
    </DataTable>
  );
};

export default Tables;
