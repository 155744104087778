import React from "react";
import DashBoardImage from "../../assets/Images/authImage.png";

const AuthSideBar = () => {
  return (
    <div className="min-h-screen style-image text-white hidden md:block px-10">
      <h1 className="text-white text-center font-bold large-text mt-16">
        Explore{" "}
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#F14D34] to-[#F2DE4E]">
          {" "}
          Truzta
        </span>{" "}
        new features!
      </h1>
      <div className="py-6">
        <p className="text-base leading-[18px] text-center">
          An AI-driven cybersecurity solution focused on predictive intelligence
          to proactively prevent initial attacks.
        </p>
      </div>
      <div className="py-14 px-5">
        <img
          src={DashBoardImage}
          alt="dash-board"
          className="rounded-3xl border-[3px] border-[#686868]"
        />
      </div>
    </div>
  );
};

export default AuthSideBar;
