import { useLocation } from "react-router-dom";

import SecureCodeReviewTable from "../../../modules/home/components/secureCodeReview/secureCodeReviewTable";
import CreateTask from "./CreateTask";

export const STATUS = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Closed",
    value: "closed",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  // {
  //   label: "Risk Accepted",
  //   value: "risk_accepted",
  // },
];
export default function MitigationTasks() {
  const { state } = useLocation();

  const columns = [
    {
      field: "name",
      header: "Task Title",
    },
    {
      field: "due_date",
      header: "Due date",
      type: "date",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: STATUS,
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: state?.employees?.map((i) => ({
        value: i.id,
        label: i.email,
      })),
    },
    {
      field: "action",
      header: "Action",
      type: "action",
    },
  ];

  return (
    <SecureCodeReviewTable
      title="Mitigation Tasks"
      columns={columns}
      url_path="/mitigation-tasks/"
      filter={{ repository: state?.data?.id }}
      AddComponent={CreateTask}
    />
  );
}
