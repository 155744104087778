import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { TbArrowUpRight } from "react-icons/tb";

import { DateTimeToDate } from "../../helpers";
import secureCodeAPI from "../../Api/secureCodeAPI.js";

const STATUS = {
  pending: "Pending",
  in_progress: "In Progress",
  completed: "Completed",
  failed: "Failed",
};

export default function ScanHistory({ repository, data, setData }) {
  const [history, setHistory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);

  const getData = () => {
    secureCodeAPI
      .post("/scans/filter/", {
        repository,
        order_by: "-id",
      })
      .then((res) => {
        setHistory(res.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const years = Array.from(
    new Set(
      history.map((item) => {
        return new Date(item.created_at).getFullYear();
      })
    )
  );

  return (
    <>
      <button
        className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
        onClick={() => setVisible(true)}
      >
        Scanning history
        <TbArrowUpRight />
      </button>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <p className="font-bold text-2xl">Scanning History</p>
        <div className="py-8 px-5">
          <div>
            {years.map((year) => {
              return (
                <>
                  <button
                    className="px-10 text-[#F14D34] font-bold text-sm flex items-center gap-3 py-3"
                    onClick={(e) => {
                      if (selectedYear === year) setSelectedYear(null);
                      else setSelectedYear(year);
                    }}
                  >
                    {year}
                    <span>
                      {year === selectedYear ? (
                        <IoIosArrowDown size={20} color="#181818" />
                      ) : (
                        <IoIosArrowForward size={20} color="#181818" />
                      )}
                    </span>
                  </button>
                  <ol className="relative border-l-2 mt-4">
                    {year === selectedYear &&
                      history
                        .filter(
                          (item) =>
                            year === new Date(item.created_at).getFullYear()
                        )
                        .map((item) => (
                          <li key={item.id} className="mb-10 ml-6">
                            <div
                              className={`absolute flex items-center mt-1 justify-center w-2 h-2 rounded-full -left-[5px] ring-4 ring-white bg-[#F14D34]`}
                            ></div>
                            <div
                              onClick={() => {
                                setData({ ...data, last_scan: item.id });
                                setVisible(false);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <p className="text-base font-semibold text-color leading-4 cursor-pointer">
                                {DateTimeToDate(item.created_at)}{" "}
                                <span className="text-xs font-normal text-[#9F9F9F] py-1">
                                  ({STATUS[item.status]})
                                </span>
                              </p>
                              <div className="py-2 flex gap-2 items-center">
                                <p className="text-xs font-normal text-[#9F9F9F] py-1">
                                  {item.commit}
                                </p>
                              </div>
                              {data?.last_scan == item.id && (
                                <div>
                                  <p className=" text-[12px] text-[#F14D34] font-normal">
                                    Current on display
                                  </p>
                                </div>
                              )}
                            </div>
                          </li>
                        ))}
                  </ol>
                </>
              );
            })}
          </div>
        </div>
      </Sidebar>
    </>
  );
}
