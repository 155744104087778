import React, { useState } from "react";
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import Toggle from "react-toggle";
import { BsCheckLg } from "react-icons/bs";
import { useEffect } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";

const Security = (props) => {
  const { isDarkMode } = props;
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleMultifactorRight, setVisibleMultifactorRight] = useState(false);
  const [checkAuthenticate, setCheckAuthenticate] = useState(false);
  const [mfaInfo, setMfaInfo] = useState([]);

  const handleChange = (value) => {
    if (!checkAuthenticate) {
      setVisibleMultifactorRight(true);
    }
  };

  useEffect(() => {
    TableApi.mfaToggleVerify().then((data) => {
      if (data.status === "success") {
        setMfaInfo(data.data);
      }
    });
  }, []);

  return (
    <div
      className={`${isDarkMode ? " text-white" : " text-color"} security-style`}
    >
      <div className="">
        <p className="font-bold text-[24px] pb-2">Password</p>
        <p>Manage your password</p>
        <br />
        <div className="mt-10">
          <label className="font-bold text-[16px]">Password</label>
          <input
            className={`mx-16 focus:outline-none px-10 ${
              isDarkMode ? "bg-[#0C0C0C]" : "bg-[#f5f5f7]"
            }`}
            placeholder="************"
            type="password"
          />
          <button
            className="text-[14px] text-[#074EE8]"
            onClick={() => setVisibleRight(true)}
          >
            Change
          </button>
        </div>
      </div>
      <hr className={`${isDarkMode && "securityhr-style"} my-16`} />
      <div className="">
        <div className="my-10 ">
          <p className="font-bold text-[24px] pb-2">
            Multifactor Authentication
          </p>
          <p>Make your account secure</p>
          <br />
          <div className="flex flex-wrap security-toggle mt-10">
            <p className=" font-bold ">Two-factor Authentication</p>
            <div className="mx-10 px-10">
              <Toggle
                checked={mfaInfo.length > 0 ? mfaInfo[0].status : false}
                icons={{
                  checked: (
                    <BsCheckLg
                      size={20}
                      style={{
                        color: "white",
                        position: "absolute",
                        left: "-6px",
                        top: "-5px",
                      }}
                    />
                  ),
                  unchecked: null,
                }}
                onChange={(e) => handleChange(e.target.checked)}
              />
            </div>
            {checkAuthenticate && (
              <div>
                <p
                  className="text-[#074EE8] text-[16px]"
                  onClick={() => setVisibleMultifactorRight(true)}
                >
                  Change authentication method
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <SidebarSlide
          title="Change password"
          description="Manage your password"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
        />
      </div>
      <div>
        <SidebarSlide
          title="Multifactor Authentication"
          description="Secure your Truzta account with multifactor verification"
          visibleRight={visibleMultifactorRight}
          setVisibleRight={setVisibleMultifactorRight}
        />
      </div>
    </div>
  );
};

export default Security;
