import { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { Sidebar } from "primereact/sidebar";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { DateSelectionToDjangoFormat, DateTimeToDate } from "../../helpers";
import secureCodeAPI from "../../Api/secureCodeAPI";
import HandleResponseError from "../../utils/error";
import { PRIORITIES } from "./ViewRepo";
import { complianceAPI } from "../../Api/CSPM/api";
import {
  handleSubmitButtonPress,
  handleSubmitButtonRelease,
} from "../../utils/utils";

export default function CreateRepo({ visible, onHide, currentDetails }) {
  const [data, setData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [openDate, setOpenDate] = useState(false);

  const handleCreate = (e) => {
    handleSubmitButtonPress(e);
    if (currentDetails) {
      secureCodeAPI
        .patch(`/repositories/${data.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
          } else {
            toast.success("Repository updated successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          handleSubmitButtonRelease(e);
        });
    } else {
      secureCodeAPI
        .post("/repositories/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
          } else {
            toast.success("Repository created successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          handleSubmitButtonRelease(e);
        });
    }
  };

  const cleanClose = () => {
    onHide();
    setData({});
  };

  const getEmployees = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <Sidebar visible={visible} position="right" onHide={onHide}>
      <p className="font-bold text-2xl">
        {currentDetails ? "Update Repository" : "Create Repository"}
      </p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter repository name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter description"
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
              }}
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">URL</label>
            <input
              className={`placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="url"
              placeholder="Enter url"
              value={data.url}
              required
              onChange={(e) => {
                setData({ ...data, url: e.target.value });
              }}
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Priority</label>
            <Dropdown
              value={data?.priority}
              onChange={(e) => setData({ ...data, priority: e.value })}
              options={PRIORITIES}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              Deadline
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data?.deadline)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenDate(!openDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data?.deadline)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        deadline: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                    minDate={new Date()}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Assignee</label>
            <Dropdown
              value={data?.assignee}
              onChange={(e) => setData({ ...data, assignee: e.value })}
              options={employees}
              optionLabel="email"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Update" : "Create"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
