import React from "react";
import logo from "../../assets/Logo/UltraHealsWhitelogo.png";
import { Link, useSearchParams } from "react-router-dom";
import { LogoDark } from "../../assets/svg";
import LogoDarkSVG from "../../assets/Logo/LogoDark.svg";

export const LoginHeader = (props) => {
  const { title, subTitle, navigate, btnText } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("regiser");

  // TODO: invitations
  // if (email) {
  //   loginWithRedirect({
  //     authorizationParams: {
  //       screen_hint: "signup",
  //       login_hint: email,
  //     },
  //   });
  // }

  return (
    <div className="py-4 px-6 border-b-[1px] border-[#DEDEDE] flex justify-between items-center bg-[#171717]">
      <div>
        <img src={LogoDarkSVG} className="h-6" alt="logo" />
      </div>
      {btnText ? (
        <div>
          <Link to={navigate}>
            {/* <div className="cursor-pointer" onClick={() => loginWithRedirect()}> */}
            <div className="cursor-pointer">
              <button className="bg-[#FFF] px-4 rounded-[16px] h-[40px] text-center text-[16px]">
                Sign In
              </button>
            </div>
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
