import React, { createElement, useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { NavLink } from "react-router-dom";
import {
  ChartIconDark,
  ChartIconLight,
  DashBoardIconDark,
  DashBoardIconLight,
  GlobeIconDark,
  GlobeIconLight,
  SystemIconDark,
  SystemIconLight,
} from "../../assets/svg";
import { AiOutlineMenu } from "react-icons/ai";
import { TbCloudLock } from "react-icons/tb";
import { TbCheckupList } from "react-icons/tb";
import { LiaCertificateSolid } from "react-icons/lia";
import { MdDashboard } from "react-icons/md";
import { MdOutlineBugReport } from "react-icons/md";
import { GoCodescan } from "react-icons/go";

import {
  attackSurfaceDropdownItems,
  digitalThreatDropdownItems,
  complianceDropdownItems,
} from "../../constants";
import Section from "./Section";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import {
  isAdminOrSuperAdmin,
  isAdminOrSuperAdminInfoAccount,
  isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin,
} from "../../utils/accessCheck";
import { MODULES } from "../../modules/sidebar/components/CreateUserForm";

export const MODULE_MAPPING = {
  drp: {
    "Brand Threat - Social media": "brand threat intelligence",
  },
};

const ExpandableSideBar = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);

  const { expandSideBar, setExpandSideBar } = props;

  const [openAttackSurfaceMenu, setOpenAttackSurfaceMenu] = useState(false);
  const [openDigitalThreatMenu, setOpenDigitalThreatMenu] = useState(false);
  const [openComplianceMenu, setOpenComplianceMenu] = useState(false);
  const [currentOpenSection, setCurrentOpenSection] = useState(null);

  const toggleSection = (section) => {
    if (currentOpenSection === section) {
      setCurrentOpenSection(null);
    } else {
      setCurrentOpenSection(section);
    }
  };

  const toggleSidebar = () => {
    const container = document.getElementById("responsiveScreen");
    if (container) {
      const width = container.offsetWidth;
      if (width > 900 && width < 1140) {
        container.classList.add("responseStyele");
      } else {
        container.classList.remove("responseStyele");
      }
    }
    setExpandSideBar(!expandSideBar);
  };

  const checkEditorPerm = (index) => {
    if (userDetails.role !== "Editor") return true;
    return MODULES[index].submodules.some((i) =>
      userDetails.module.includes(i)
    );
  };

  return (
    <nav className={`my-5 ${isDarkMode ? "text-white" : "text-color"}`}>
      <div
        onClick={toggleSidebar}
        className={`ml-5 my-2 py-2 cursor-pointer flex items-center w-full ${
          isDarkMode ? "text-white" : "text-color"
        }`}
      >
        <AiOutlineMenu size={24} />
      </div>

      {userDetails?.compliance && checkEditorPerm(0) && (
        <NavLink
          to="/compliance"
          className={({ isActive }) =>
            isActive ? "active top-level" : "inactive"
          }
        >
          <div className="my-2">
            <div
              className="flex items-center gap-[10px] py-3 px-[10px]"
              onClick={() => setOpenComplianceMenu(!openComplianceMenu)}
            >
              <div className="inner-bar" />
              <TbCheckupList size={24} />
              <h1 className="text-base font-bold">Compliance</h1>
            </div>
            {openComplianceMenu && (
              <div className="relative">
                {complianceDropdownItems.map((item) => {
                  if (
                    item.isAdmin &&
                    !isAdminOrSuperAdminInfoAccount(
                      userDetails.role,
                      userDetails.email
                    )
                  )
                    return null;
                  if (
                    userDetails.role === "Employee" &&
                    item.section !== "People"
                  )
                    return null;
                  if (
                    userDetails.role === "Vendor" &&
                    item.link !== "Vendor Management"
                  )
                    return null;
                  if (item.section) {
                    if (
                      userDetails.role === "Editor" &&
                      !userDetails.module.includes(item.section)
                    )
                      return null;
                    return (
                      <div
                        key={item.section}
                        className="section-border"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <Section
                          textColor={isDarkMode ? "text-white" : "text-color"}
                          section={item.section}
                          subLinks={item.subLinks.filter((i) => {
                            if (
                              i.isAdmin &&
                              !isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin(
                                userDetails.role
                              )
                            ) {
                              return false;
                            }
                            return true;
                          })}
                          isDarkMode={isDarkMode}
                          isOpen={currentOpenSection === item.section}
                          toggleSection={() => toggleSection(item.section)}
                          width="w-[100%]"
                          padding="pl-[52px] pr-3"
                          linkPadding="pl-[75px]"
                          icon={item.icon}
                        />
                      </div>
                    );
                  } else {
                    if (
                      userDetails.role === "Editor" &&
                      !userDetails.module.includes(item.link)
                    )
                      return null;
                    return (
                      <NavLink to={item.navLink}>
                        <div key={item.link} className="section-border">
                          <div
                            className={`cursor-pointer py-[12.5px] pl-[52px] pr-3 capitalize flex items-center gap-2`}
                            onClick={toggleSection}
                          >
                            <div>
                              {item.icon ? createElement(item.icon) : null}
                            </div>
                            <div className="flex justify-between items-center w-full">
                              <h1 className="text-base">{item.link}</h1>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </NavLink>
      )}

      {userDetails?.cspm && checkEditorPerm(1) && (
        <NavLink
          to="/cloud-security-posture-management"
          className={({ isActive }) =>
            isActive ? "active top-level" : "inactive"
          }
        >
          <div className="my-2">
            <div>
              <div className="flex items-center gap-[10px] py-3 px-[10px]">
                <div className="inner-bar" />
                <TbCloudLock size={24} />
                <h1 className="text-base font-bold">
                  Cloud Security Posture Management
                </h1>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      {userDetails?.secure_code_review && (
        <NavLink
          to="/secure-code-review"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="my-2">
            <div>
              <div className="flex items-center gap-[10px] py-3 px-[10px]">
                <div className="inner-bar" />
                <GoCodescan size={24} />
                <h1 className="text-base font-bold">Secure Code Review</h1>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      {userDetails?.pentest_as_a_service && (
        <NavLink
          to="/ptaas"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="my-2">
            <div>
              <div className="flex items-center gap-[6px] py-3 px-[8px]">
                <div className="inner-bar" />
                <MdOutlineBugReport style={{ fontSize: "32px" }} />
                <h1 className="text-base font-bold">Pentest as a Service</h1>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      {userDetails?.asm && checkEditorPerm(2) && (
        <NavLink
          to="/attack-surface-management"
          className={({ isActive }) =>
            isActive ? "active top-level" : "inactive"
          }
        >
          <div className="my-2">
            <div
              onClick={() => setOpenAttackSurfaceMenu(!openAttackSurfaceMenu)}
            >
              <div
                className={`flex items-center gap-[10px] py-3 px-[10px]  ${
                  openAttackSurfaceMenu ? "bg-[#18181819]" : ""
                }`}
              >
                <div className="inner-bar" />
                <img
                  src={`${isDarkMode ? ChartIconDark : ChartIconLight}`}
                  alt="chart"
                  className="cursor-pointer"
                />
                <h1 className="text-base font-bold">
                  Attack Surface Management
                </h1>
              </div>
            </div>
            {openAttackSurfaceMenu && (
              <div className="relative">
                {attackSurfaceDropdownItems.map((item, index) => (
                  <div key={index} className="section-border">
                    <Section
                      textColor={isDarkMode ? "text-white" : "text-color"}
                      section={item.section}
                      subLinks={item.subLinks}
                      isDarkMode={isDarkMode}
                      isOpen={currentOpenSection === item.section}
                      toggleSection={() => toggleSection(item.section)}
                      width="w-[100%]"
                      padding="pl-[52px] pr-3"
                      linkPadding="pl-[75px]"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </NavLink>
      )}

      {userDetails?.voc && checkEditorPerm(2) && (
        <NavLink
          to="/vulnerability-operation-center"
          className={({ isActive }) =>
            isActive ? "active top-level" : "inactive"
          }
        >
          <div className="my-2">
            <div>
              <div className="flex items-center gap-[10px] py-3 px-[10px]">
                <div className="inner-bar" />
                <img
                  src={`${isDarkMode ? SystemIconDark : SystemIconLight}`}
                  alt="system"
                  className="cursor-pointer"
                />
                <h1 className="text-base font-bold">
                  Vulnerability Operation Center
                </h1>
              </div>
            </div>
          </div>
        </NavLink>
      )}

      {userDetails.drp && checkEditorPerm(3) && (
        <NavLink
          to="/digital-risk-protection"
          className={({ isActive }) =>
            isActive ? "active top-level" : "inactive"
          }
        >
          <div className="my-2">
            <div
              onClick={() => setOpenDigitalThreatMenu(!openDigitalThreatMenu)}
            >
              <div
                className={`flex items-center gap-[10px] py-3 px-[10px] ${
                  openDigitalThreatMenu ? "bg-[#18181819]" : ""
                }`}
              >
                <div className="inner-bar" />
                <img
                  src={`${isDarkMode ? GlobeIconDark : GlobeIconLight}`}
                  alt="globe"
                  className="cursor-pointer"
                />
                <h1 className="text-base font-bold">Digital Risk Protection</h1>
              </div>
            </div>
            {openDigitalThreatMenu && (
              <div className="relative border-none">
                {digitalThreatDropdownItems.map((item, index) => (
                  <div key={index} className="section-border">
                    <Section
                      textColor={isDarkMode ? "text-white" : "text-color"}
                      key={index}
                      section={item.section}
                      subLinks={item.subLinks}
                      isDarkMode={isDarkMode}
                      isOpen={currentOpenSection === item.section}
                      toggleSection={() => toggleSection(item.section)}
                      width="w-[100%]"
                      padding="pl-[52px] pr-3"
                      linkPadding="pl-[75px]"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </NavLink>
      )}

      {userDetails?.bti && checkEditorPerm(4) && (
        <NavLink
          to="/brand-threat-intelligence"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <div className="my-2">
            <div>
              <div className="flex items-center gap-[6px] py-3 px-[8px]">
                <div className="inner-bar" />
                {/* <img
                  src={`${isDarkMode ? SystemIconDark : SystemIconLight}`}
                  alt="system"
                  className="cursor-pointer"
                /> */}
                {isDarkMode ? (
                  <LiaCertificateSolid
                    style={{ fontSize: "32px", color: "#FFF" }}
                  />
                ) : (
                  <LiaCertificateSolid style={{ fontSize: "32px" }} />
                )}
                <h1 className="text-base font-bold">
                  Brand Threat Intelligence
                </h1>
              </div>
            </div>
          </div>
        </NavLink>
      )}
    </nav>
  );
};

export default ExpandableSideBar;
