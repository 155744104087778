import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { complianceAPI } from "../../Api/CSPM/api";
import { ThemeContext } from "../../context/ThemeContext";
import {
  LogoDark,
  LogoLight,
  LogoDarkSVG,
  LogoLightSVG,
} from "../../assets/svg";
import Compliance from "../../modules/home/components/compliance/trustcenter/Compliance";
import Controls from "../../modules/home/components/compliance/trustcenter/Controls";
import AddAccess from "../../modules/home/components/compliance/trustcenter/AddAccess";
import { toast } from "react-toastify";

export default function TrustCenterPublic() {
  const { token } = useParams();
  const { isDarkMode } = useContext(ThemeContext);
  const [data, setData] = useState(null);
  const [visibleRequest, setVisibleRequest] = useState(false);

  const getData = () => {
    complianceAPI
      .get(`/trustcenter/${token}/`)
      .then((response) => {
        console.log(response);
        if (response.status === "error") {
          toast.error(response.message);
        } else {
          setData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className={`h-screen flex flex-col ${
        isDarkMode ? "header-dark text-white" : "text-black"
      } overflow-x-hidden`}
    >
      <div className="w-full">
        <div
          className={`py-4 px-6 border-b-[1px] flex justify-between items-center ${
            isDarkMode ? "header-dark border-dark" : "border-color"
          }`}
        >
          <Link to="/">
            <img src={isDarkMode ? LogoDarkSVG : LogoLightSVG} alt="Logo" />
          </Link>
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff]"
            onClick={() => setVisibleRequest(true)}
          >
            Request Private Access
          </button>
        </div>
      </div>
      <div className="container mx-auto my-2">
        <div className="flex flex-col gap-3">
          <div className="border p-3 rounded-md">
            <p className="text-xl font-bold">About</p>
            {data?.about ? (
              <p className="p-3">{data.about}</p>
            ) : (
              <div className="flex justify-center items-center h-[200px]">
                <p className="text-gray-500">user has not added any about</p>
              </div>
            )}
          </div>
          <div className="border p-3 rounded-md">
            {data?.compliance && <Compliance _data={data?.compliance} />}
          </div>
          <div className="border p-3 rounded-md">
            {data?.control && <Controls _data={data?.control} />}
          </div>
        </div>
      </div>
      <AddAccess
        isPublic={true}
        visible={visibleRequest}
        onHide={() => {
          setVisibleRequest(false);
        }}
      />
    </div>
  );
}
