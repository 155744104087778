import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { ThemeContext } from "../../../../context/ThemeContext";
import secureCodeAPI from "../../../../Api/secureCodeAPI";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import CreateNewAccessToken from "./CreateNewAccessToken";
import paginationTemplate from "../../../../utils/paginationTemplate";
import { DateTimeToDate } from "../../../../helpers";
import { toast } from "react-toastify";
import { isAgentOrAdminOrSuperAdmin } from "../../../../utils/accessCheck";
import { complianceAPI } from "../../../../Api/CSPM/api";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { useSelector } from "react-redux";

export default function AccessToken() {
  const { isDarkMode } = useContext(ThemeContext);
  const { role } = useSelector(getUserDetails);

  const [data, setData] = useState(null);
  const [employees, setEmployees] = useState([]);
  const dt = useRef(null);

  const getData = () => {
    secureCodeAPI
      .get("/token/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployees = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getEmployees();
  }, []);

  const columns = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "token",
      name: "Token",
    },
    isAgentOrAdminOrSuperAdmin(role) && {
      field: "org_id",
      header: "User",
      type: "select",
      value: employees?.map((i) => ({
        value: i.id,
        label: i.email,
      })),
    },
    {
      key: "created_at",
      name: "Date Created",
      type: "date",
    },
    {
      key: "expiry",
      name: "Expiry date",
      type: "date",
    },
    {
      key: "Scopes",
      name: "scopes",
    },
    {
      key: "last_used",
      name: "Last used",
      type: "date",
    },
    {
      field: "action",
      header: "Action",
      type: "action",
    },
  ];

  const onDelete = (id) => {
    secureCodeAPI
      .delete(`/token/${id}/`)
      .then(() => {
        getData();
        toast.success("Token deleted successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className={`${
        isDarkMode ? "header-dark" : "bg-white"
      } lg:h-fit w-full rounded-2xl mt-6 p-5 `}
    >
      <div className="flex justify-between m-3">
        <p className="text-xl font-bold pb-2">Access Token</p>
        <CreateNewAccessToken getData={getData} />
      </div>
      {!data ? (
        <SkeletonDataTable columns={columns} length={5} />
      ) : (
        <DataTable
          value={data}
          ref={dt}
          className={`${
            isDarkMode ? "dark-mode text-dark" : " text-color"
          } text-xs`}
          paginatorTemplate={paginationTemplate()}
        >
          {columns.map((col, i) => {
            if (col.type === "date") {
              return (
                <Column
                  key={i}
                  field={col.key}
                  header={col.name}
                  body={(rowData) =>
                    rowData[col.key] ? DateTimeToDate(rowData[col.key]) : null
                  }
                />
              );
            }
            return <Column key={col.key} field={col.key} header={col.name} />;
          })}
          <Column
            field="action"
            header="Action"
            body={({ id }) => (
              <button
                onClick={() => {
                  confirmDialog({
                    message: "Are you sure you want to delete this token?",
                    header: "Delete Token",
                    acceptClassName: "p-button-danger",
                    accept: () => onDelete(id),
                  });
                }}
              >
                <MdOutlineDeleteOutline />
              </button>
            )}
          />
        </DataTable>
      )}
      <ConfirmDialog />
    </div>
  );
}
