import React, { useContext, useEffect, useState } from "react";
import { tabs } from "../../../constants";
import { ThemeContext } from "../../../context/ThemeContext";
import UserInformation from "./UserInformation";
import UserManagement from "./UserManagement";
import MyProjects from "./MyProjects";
import Security from "./Security";
import Subscription from "./Subscription";
import SecurityScreen from "./SecurityScreen";
import Integration from "./Integration";
import {
  isAdminOrSuperAdmin,
  isAdminOrSuperAdminInfoAccount,
  isAuditorOrSubscriberOrAdminOrSuperAdmin,
  isSuperAdmin,
} from "../../../utils/accessCheck";
import Departments from "../../../screens/compliance/Departments";
import Entities from "../../../screens/compliance/Entities";
import Settings from "./settings/Settings";
import ComplianceSettings from "./compliance/ComplianceSettings";
import { useSearchParams } from "react-router-dom";
import AccessToken from "./secureCodeReview/AccessToken";

const DashBoard = () => {
  const userInformation = JSON.parse(localStorage.getItem("userDetails"));
  const [selectedTab, setSelectedTab] = useState(
    userInformation?.role === "Vendor" ? "security" : "userInformation"
  );

  const { isDarkMode } = useContext(ThemeContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const rule_of_engagement = searchParams.get("rule_of_engagement");
  useEffect(() => {
    if (rule_of_engagement) {
      setSelectedTab("myProjects");
    }
  }, [rule_of_engagement]);

  const showActiveTabComponent = () => {
    switch (selectedTab) {
      case "userInformation":
        return (
          <UserInformation
            isDarkMode={isDarkMode}
            title="Users"
            subtitle="Lorem ipsum dolor sit amet, consec tetur adipiscing"
            btnText={true}
          />
        );
      // case 'userManagement':
      //   return <UserManagement />
      case "myProjects":
        return (
          <MyProjects
            title="Projects"
            subtitle="Lorem ipsum dolor sit amet, consec tetur adipiscing"
            btnText={true}
          />
        );
      case "security":
        // return <Security isDarkMode={isDarkMode} />;
        return <SecurityScreen isDarkMode={isDarkMode} />;
      case "subscription":
        return <Subscription />;
      case "integrations":
        return <Integration />;
      case "departments":
        return <Departments />;
      case "entities":
        return <Entities />;
      case "settings":
        return <Settings />;
      case "compliance":
        return <ComplianceSettings />;
      case "access_token":
        return <AccessToken/>
      default:
        return;
    }
  };

  return (
    <div>
      {/* <div>
        <h1 className="text-5xl font-bold">
          Hello, {userInformation?.first_name}!
        </h1>
      </div> */}
      <div
        className={`flex gap-5 border-b-2 my-6 mx-8 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {tabs.map((tab) => {
          if (
            tab.admin &&
            !isAuditorOrSubscriberOrAdminOrSuperAdmin(userInformation?.role)
          )
            return;
          if (tab.superAdmin && !isSuperAdmin(userInformation.role)) return;
          if (
            ["departments", "entities"].includes(tab.tab) &&
            !userInformation.compliance
          )
            return;
          if (userInformation.role === "Vendor" && tab.tab != "security")
            return;
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setSelectedTab(tab.tab)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  selectedTab === tab.tab
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.tabName}
              </p>
              <div
                className={`${
                  selectedTab === tab.tab
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>

      <div className="py-4 px-8">{showActiveTabComponent()}</div>
    </div>
  );
};

export default DashBoard;
