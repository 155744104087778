import { Sidebar } from "primereact/sidebar";
import { DateTimeToDate } from "../../../helpers";

export default function ViewVulnerability({
  currentDetails,
  setCurrentDetails,
}) {
  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={() => setCurrentDetails(null)}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl text-center">
        {currentDetails?.rule_id}
      </p>
      <div className="grid grid-cols-2 gap-3 my-4">
        <div>
          <h1 className="text-sm text-color font-bold">Vulnerability ID</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.rule_id}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Due date</h1>
          <p className="text-xs text-color font-normal leading-5 break-all">
            {DateTimeToDate(currentDetails?.due_date) || "-"}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Status</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.status}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Severity</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.severity}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Discovery date</h1>
          <p className="text-xs text-color font-normal leading-5">
            {DateTimeToDate(currentDetails?.createdAt)}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Last Updated</h1>
          <p className="text-xs text-color font-normal leading-5">
            {DateTimeToDate(currentDetails?.updated_at)}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Line</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.line}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Column</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.column}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Type</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.type}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Language</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.language}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Confidence</h1>
          <p className="text-xs text-color font-normal leading-5">
            {currentDetails?.confidence}
          </p>
        </div>
      </div>
      <h1 className="text-sm text-color font-bold">File</h1>
      <p className="text-xs text-color font-normal leading-5">
        {currentDetails?.file}
      </p>
      <h1 className="text-sm text-color font-bold">Code</h1>
      <p className="text-xs text-color font-normal leading-5">
        {currentDetails?.code}
      </p>
      <h1 className="text-sm text-color font-bold">Details</h1>
      <p className="text-xs text-color font-normal leading-5">
        {currentDetails?.details}
      </p>
    </Sidebar>
  );
}
