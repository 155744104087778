import { useContext, useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { ThemeContext } from "../../../context/ThemeContext";
import TableFilter from "../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../modules/SkeletonAnimation/SkeletonDataTable";

import paginationTemplate from "../../../utils/paginationTemplate";
import AddEmployee from "../../../modules/home/components/compliance/people/AddEmployee";
import { complianceAPI } from "../../../Api/CSPM/api";
import IntegratePeople from "../../../modules/home/components/compliance/people/IntegratePeople";
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import { setUserTableData } from "../../../redux-toolkit/redux/TableData";
import ImportPeoples from "../../../modules/home/components/compliance/people/ImportPeoples";
import { getFilter } from "../../../utils/utils";
import ResendInvitation from "../../../modules/home/components/compliance/people/ResendInvitation";
import { DateTimeToDate } from "../../../helpers";

export default function Employee() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDarkMode } = useContext(ThemeContext);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({
    status: [true, false],
  });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [addVisibility, setAddVisibility] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [view, setView] = useState(false);

  const getFilterData = () => {
    complianceAPI
      .get("/employees/filter/")
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    setLoading(true);
    complianceAPI
      .post("/employees/filter/", {
        ...getFilter(filterData, filterParams),
        role: ["Employee"],
      })
      .then((res) => {
        setData(
          res.data.map((item) => ({
            ...item,
            status: item.status ? "Active" : "Pending",
          }))
        );
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartments(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
    getDepartment();
  }, []);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  const columns = [
    {
      field: "first_name",
      header: "First Name",
      value: filterData?.first_name,
    },
    { field: "last_name", header: "Last Name", value: filterData?.last_name },
    {
      field: "email",
      header: "Email",
      value: filterData?.email,
    },
    {
      field: "department",
      header: "Department",
      value: filterData?.department,
    },
    {
      field: "reporting_manager",
      header: "Reporting Manager",
      value: filterData?.reporting_manager,
    },
    {
      field: "mfa",
      header: "MFA",
      value: filterData?.mfa,
    },
    {
      field: "date_joined",
      header: "Joining date",
      value: filterData?.join_date,
      type: "date",
    },
    {
      field: "exit date",
      header: "Exit date",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Active",
          value: true,
        },
        {
          label: "Pending",
          value: false,
        },
      ],
    },
  ];

  const integrateOrImport = () => {
    return (
      <>
        <ImportPeoples />
        <IntegratePeople />
        <ResendInvitation data={data.filter((i) => i.status === "Pending")} />
      </>
    );
  };

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap  dashboard-table">
          <p className="font-bold">
            Employee <span> ({data.length || 0}) </span>
          </p>
        </div>
        <>
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            columns={columns}
            tableData={data}
            tableHeader={columns}
            dt={ref}
            isDarkMode={isDarkMode}
            btnName="Add"
            btnText={true}
            setVisibleRight={() => {
              setAddVisibility(true);
            }}
            IntegrationComponent={integrateOrImport}
          />
          {loading ? (
            <SkeletonDataTable
              columns={[
                ...columns,
                { field: "full_info", header: "Full info" },
              ]}
            />
          ) : (
            <DataTable
              ref={ref}
              removableSort
              value={data.map((i) => {
                return {
                  ...i,
                  department: departments?.find((j) => j.id == i.department)
                    ?.name,
                  date_joined: DateTimeToDate(i.date_joined),
                };
              })}
              paginator
              rows={10}
              sortField="severity"
              sortOrder={-1}
              sortMode="multiple"
              globalFilter={globalFilter}
              paginatorTemplate={paginationTemplate()}
              className={`${
                isDarkMode ? "dark-mode text-dark" : "text-color"
              } text-xs cursor-pointer`}
              onRowClick={({ data }) => {
                navigate(`/compliance/employee/${data.id}`);
              }}
            >
              {columns.map((col, i) => {
                return (
                  <Column
                    key={i}
                    field={col.field}
                    header={col.header}
                    sortable
                  />
                );
              })}
              <Column
                key="action"
                field="action"
                header="Action"
                body={(rowData) => (
                  <SplitButton
                    icon={<FiMoreVertical size={24} />}
                    className="p-button-text outline-none split-style"
                    model={[
                      {
                        label: "Edit",
                        icon: "pi pi-refresh",
                        command: () => {
                          setCurrentDetails(rowData);
                          dispatch(setUserTableData(rowData));
                          setView(false);
                        },
                      },
                      {
                        label: "View",
                        icon: "pi pi-times",
                        command: () => {
                          setCurrentDetails(rowData);
                          dispatch(setUserTableData(rowData));
                          setView(true);
                        },
                      },
                    ]}
                  ></SplitButton>
                )}
              />
            </DataTable>
          )}
        </>
      </div>
      <AddEmployee
        visible={addVisibility}
        onHide={() => {
          setAddVisibility(false);
          getFilterData();
        }}
      />
      <div className="side-user-info-style">
        <SidebarSlide
          title="User Information"
          description="Edit the user account"
          visibleRight={currentDetails}
          setVisibleRight={setCurrentDetails}
          userTableViewData={view}
          role={[]}
          getUserInformationTableData={getData}
        />
      </div>
    </>
  );
}
