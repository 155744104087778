import { Routes, Route, Navigate } from "react-router-dom";
import { Login, CompanyLogin, AuthenticateLogin } from "./screens/login";
import { SignUp, AuthenticateSignUp } from "./screens/signup";
import {
  ForgotPassword,
  NewPassword,
  PasswordUpdated,
  RecoveryLink,
} from "./screens/forgotPassword";
import SkeletonAnimation from "./modules/SkeletonAnimation/SkeletonAnimation";
import { PrivateRoute } from "./route/Private";
import Root from "./route/Root";
import BrandMonitor from "./screens/brandMonitor/BrandMonitor";
import AttackSurfaceManagement from "./screens/attackSurface/AttackSurfaceManagement";
import VulnerabilityOperationCenter from "./screens/vulnerabilityOperation/VulnerabilityOperationCenter";
import CardDesign from "./ErrorBoundary";
import MyProfile from "./screens/myprofile/MyProfile";
import LoginCarousel from "./screens/carousalScreen/LoginCarousel";
import DigitalRiskProtection from "./screens/digitalRiskProtection/DigitalRiskProtection";
import PageNotFound from "./PageNotFound";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NewUserPassword from "./screens/forgotPassword/NewUserPassword";
import CloudPostureSecurityManagement from "./screens/CSPM/CloudPostureSecurityManagement";
import SecurityAwareness from "./screens/compliance/people/SecurityAwareness";
import ResourceMapping from "./screens/CSPM/aws/ResourceMapping";
import CPSMTable from "./screens/CSPM/aws/CSPMTable";
import Redirect from "./modules/home/components/Integrations/Redirect";
import Compliance from "./screens/compliance";
import BrandThreatIntelligenceNew from "./screens/brandThreatIntelligence/BrandThreatIntelligence";
import TrustCenterPublic from "./screens/compliance/TrustCenterPublic";
import { useSelector } from "react-redux";
import { getUserDetails } from "./redux-toolkit/redux/auth";
import Training from "./screens/compliance/people/SecurityAwareness/Training";
import Quiz from "./screens/compliance/people/SecurityAwareness/Training/Quiz";
import ForcePasswordReset from "./modules/SignUp/components/ForcePasswordReset";
import Authorize from "./screens/login/Authorize";
import { useEffect } from "react";
import CompleteProfile from "./screens/signup/CompleteProfile";
import PublicRoute from "./route/PublicRoute";
import AdminDashBoard from "./modules/adminDashboard/AdminDashBoard";
import ViewUser from "./modules/adminDashboard/ViewUser";
import PTAAS from "./screens/PTAAS";
import ViewTask from "./modules/adminDashboard/scans/ViewTask";
import ViewReport from "./screens/PTAAS/ViewReport";
import ViewCertificate from "./screens/PTAAS/ViewCertificate";
import SecureCodeReview from "./screens/secureCodeReview";
import ViewRepo from "./screens/secureCodeReview/ViewRepo";

function App() {
  const userDetails = useSelector(getUserDetails);

  let auth_routes = [
    { path: "my-profile", element: <MyProfile /> },
    { path: "/integrations/redirect/:service", element: <Redirect /> },
    { path: "/complete-profile", element: <CompleteProfile /> },
    { path: "/dashboard", element: <AdminDashBoard /> },
    { path: "/dashboard/user", element: <ViewUser /> },
    { path: "/dashboard/scan", element: <ViewTask /> },

    // { path: "/trust-center/:token", element: <TrustCenterPublic /> },
  ];

  if (userDetails.asm) {
    auth_routes = [
      ...auth_routes,
      {
        path: "/attack-surface-management",
        element: <AttackSurfaceManagement />,
      },
      {
        path: "/attack-surface-management/:slug",
        element: <AttackSurfaceManagement />,
      },
    ];
  }

  if (userDetails.voc) {
    auth_routes = [
      ...auth_routes,
      {
        path: "/vulnerability-operation-center",
        element: <VulnerabilityOperationCenter />,
      },
    ];
  }

  if (userDetails.drp) {
    auth_routes = [
      ...auth_routes,
      { path: "/digital-risk-protection", element: <DigitalRiskProtection /> },
      {
        path: "/digital-risk-protection/:slug",
        element: <DigitalRiskProtection />,
      },
    ];
  }

  if (userDetails.bti) {
    auth_routes = [
      ...auth_routes,
      {
        path: "/brand-threat-intelligence",
        element: <BrandThreatIntelligenceNew />,
      },
      {
        path: "/brand-threat-intelligence/:slug",
        element: <BrandThreatIntelligenceNew />,
      },
    ];
  }

  if (userDetails.cspm) {
    auth_routes = [
      ...auth_routes,
      {
        path: "/cloud-security-posture-management",
        element: <CloudPostureSecurityManagement />,
      },
      {
        path: "/cloud-security-posture-management/:slug",
        element: <CPSMTable />,
      },
      {
        path: "/resource-mapping",
        element: <ResourceMapping />,
      },
    ];
  }
  if (userDetails.secure_code_review) {
    auth_routes = [
      ...auth_routes,
      {
        path: "/secure-code-review",
        element: <SecureCodeReview />,
      },
      {
        path: "/secure-code-review/details",
        element: <ViewRepo />,
      },
    ];
  }

  if (userDetails.compliance) {
    if (
      userDetails.role !== "Editor" ||
      userDetails.module.includes("People")
    ) {
      auth_routes = [
        ...auth_routes,
        {
          path: "/compliance/security-awareness/training",
          element: <Training />,
        },
        {
          path: "/compliance/security-awareness/quiz/",
          element: <Quiz />,
        },
      ];
    }

    if (
      userDetails.role !== "Editor" ||
      userDetails.module.includes("Cloud Security")
    ) {
      auth_routes = [
        ...auth_routes,
        { path: "/compliance-cloud/", element: <Navigate to="/compliance" /> },
        { path: "/compliance-cloud/:slug", element: <CPSMTable /> },
      ];
    }

    auth_routes = [
      ...auth_routes,
      { path: "/compliance", element: <Compliance /> },
      { path: "/compliance/:slug", element: <Compliance /> },
      { path: "/compliance/:slug/:id", element: <Compliance /> },
    ];
  }

  auth_routes = [...auth_routes, { path: "/ptaas", element: <PTAAS /> }];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <PageNotFound />,
      children: [
        { path: "/signUp", element: <SignUp /> },
        { path: "/login", element: <Login /> },
        { path: "/authorize", element: <Authorize /> },
        // { path: "/login/company", element: <CompanyLogin /> },
        { path: "/login/authenticate", element: <AuthenticateLogin /> },
        // { path: "/signup/authenticate", element: <AuthenticateSignUp /> },
        { path: "/reset/password", element: <ForgotPassword /> },
        // { path: "/reset/recoveryLink", element: <RecoveryLink /> },
        { path: "/reset/set-password/:token/:user", element: <NewPassword /> },
        { path: "/reset/newPassword", element: <NewPassword /> },
        { path: "/register/:token/:user", element: <NewUserPassword /> },
        // { path: "/reset/successful", element: <PasswordUpdated /> },
        // { path: "/trust-center/:token", element: <TrustCenterPublic /> },
        { path: "/password-change-required", element: <ForcePasswordReset /> },
        { path: "/skeleton", element: <SkeletonAnimation /> },
        { path: "/", element: <LoginCarousel /> },
        { path: "/logout", element: <Navigate to="/" /> },
        { path: "/404", element: <div>Choose the correct path</div> },
      ],
    },
    {
      element: <PublicRoute />,
      errorElement: <PageNotFound />,
      children: [
        { path: "/trust-center/:token", element: <TrustCenterPublic /> },
        { path: "/pentest-report/:unique_id", element: <ViewReport /> },
        {
          path: "/pentest-certificate/:unique_id",
          element: <ViewCertificate />,
        },
      ],
    },
    {
      element: <PrivateRoute />,
      errorElement: <PageNotFound />,
      children: [
        ...auth_routes,
        {
          path: "*",
          element: <Navigate replace to="/404" />,
        },
      ],
    },
  ]);

  useEffect(() => {}, [userDetails]);

  return (
    <>
      <RouterProvider router={router} />
      {/* <Routes>
        <Route path="/" element={<Root />}>
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/company" element={<CompanyLogin />} />
          <Route path="/login/authenticate" element={<AuthenticateLogin />} />
          <Route path="/signup/authenticate" element={<AuthenticateSignUp />} />
          <Route path="/reset/password" element={<ForgotPassword />} />
          <Route path="/reset/recoveryLink" element={<RecoveryLink />} />
          <Route path="/reset/newPassword" element={<NewPassword />} />
          <Route path="/reset/successful" element={<PasswordUpdated />} />
          <Route path="/skeleton" element={<SkeletonAnimation />} />
          <Route path="/cardDesign" element={<CardDesign />} />
          <Route path="/" element={<LoginCarousel />} />
          <Route path="/404" element={<div>Choose the correct path</div>} />
          
        </Route>
        <Route exact element={<PrivateRoute />}>
          <Route exact path="/my-profile" element={<MyProfile />} />
          <Route
            exact
            path="/dashboard"
            element={<AttackSurfaceManagement />}
          />
          <Route exact path="/brandTreat" element={<BrandMonitor />} />
          <Route
            exact
            path="/attack-surface-management"
            element={<AttackSurfaceManagement />}
          />
          <Route
            exact
            path="/attack-surface-management/:slug"
            element={<AttackSurfaceManagement />}
          />
          <Route
            exact
            path="/vulnerability-operation-center"
            element={<VulnerabilityOperationCenter />}
          />
          <Route
            exact
            path="/digital-risk-protection"
            element={<DigitalRiskProtection />}
          />
            <Route path="/404" element={<div>Choose the correct path</div>} />
          <Route path="*" element={<Navigate replace to="/404"/>} />
          <Route path="/ErrorPage" element={<PageNotFound/>}/>
        </Route>
       
      </Routes> */}
    </>
  );
}

export default App;
